import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Stars from "../components/stars"
import { Link } from "gatsby"

const ReviewTemplate = (data, location) => {
  const { Title, Rating, Date, Review, Customer_Name } = data.data.airtable.data
  const siteTitle = `Tendlite Scam`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={Title} />
      <div className="back-button">
        <Link to="/">Back to Reviews</Link>
      </div>
      <div className="review">
        <h2>{Title}</h2>
        <p>
          <p>
            by {Customer_Name} <Stars Rating={Rating} />
          </p>
        </p>
        <div className="review-body">{Review}</div>
      </div>
      <div className="back-button bottom">
        <Link to="/">Back to Reviews</Link>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query getSingleReview($data__ID: Int) {
    airtable(data: { ID: { eq: $data__ID } }) {
      data {
        Date
        ID
        Rating
        Review
        Title
        Customer_Name
      }
    }
  }
`

export default ReviewTemplate
